import { message } from 'antd';
import { all, AllEffect, call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import API from 'api';
import { MESSAGES } from 'modules/Common/constants';
import { IEmployeeValues } from 'modules/Common/types/employee';
import { BankHolidayBaseType, BankHolidayType } from 'modules/Common/types/hrModuleTypes';

import {
	CreateTimeOffRequestType,
	GetTimeOffRequestDurationType,
	TimeOffStaticsPerEmployeeRequestedType,
	UpdateTimeOffRequestType,
} from '../components/HRRequestEditTimeOffModal/HRRequestEditTimeOffModal.types';
import { combineTimeOffStatistics } from '../utils/hrHelpers';

import { ActionTypes, GenericAction } from './types';

export const createSagas = (TYPES: ActionTypes) => {
	function* getEmployeeStatusesSaga(): Generator<CallEffect | PutEffect, void> {
		try {
			const employeeStatuses = yield call(API.employeeService.getEmployeeStatuses);

			yield put({ type: TYPES.GET_EMPLOYEE_STATUSES.SUCCESS, payload: employeeStatuses });
		} catch (error) {
			yield put({ type: TYPES.GET_EMPLOYEE_STATUSES.FAILED, payload: error });
		}
	}

	function* getOnOffBoardingSettingsSaga(): Generator<CallEffect | PutEffect, void> {
		try {
			const settings = yield call(API.employeeService.getOnOffBoardingSettings);

			yield put({ type: TYPES.GET_ONOFF_BOARDING_SETTINGS.SUCCESS, payload: settings });
		} catch (error) {
			yield put({ type: TYPES.GET_ONOFF_BOARDING_SETTINGS.FAILED, payload: error });
		}
	}

	function* setOnOffBoardingSettingsSaga(
		action: GenericAction<{ employeeId: number }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload } = action;

			yield call(API.employeeService.setOnOffBoardingSettings, payload);

			yield put({ type: TYPES.SET_ONOFF_BOARDING_SETTINGS.SUCCESS });

			yield call(message.success, MESSAGES.successfullyChangedSettings);
		} catch (error) {
			yield put({ type: TYPES.SET_ONOFF_BOARDING_SETTINGS.FAILED, payload: error });
		}
	}

	function* createEmployeeSaga(
		action: GenericAction<IEmployeeValues>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.employeeService.addEmployee, payload);

			yield put({ type: TYPES.CREATE_EMPLOYEE.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyCreatedEmployeeAccount);
		} catch (error) {
			yield put({ type: TYPES.CREATE_EMPLOYEE.FAILED, payload: error });
		}
	}

	function* editEmployeeSaga(
		action: GenericAction<{ isSuperUser: boolean; employeeId: string; values: IEmployeeValues }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { callback } = action;
			const { isSuperUser, employeeId, values } = action.payload;

			const currentEndpoint = isSuperUser
				? API.employeeService.editEmployeeSuperUser
				: API.employeeService.editEmployeeNonSuperUser;
			yield call(currentEndpoint, employeeId, values);

			yield put({ type: TYPES.EDIT_EMPLOYEE.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.employeeAccountUpdated);
		} catch (error) {
			yield put({ type: TYPES.EDIT_EMPLOYEE.FAILED, payload: error });
		}
	}

	function* getOwnProfileSaga(
		action: GenericAction<{ values: IEmployeeValues }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const employeeData = yield call(API.employeeService.getOwnProfileSuperUser);

			yield put({ type: TYPES.GET_OWN_PROFILE.SUCCESS, payload: employeeData });
		} catch (error) {
			yield put({ type: TYPES.GET_OWN_PROFILE.FAILED, payload: error });
		}
	}

	function* editOwnProfileSaga(
		action: GenericAction<{ isSuperUser: boolean; values: IEmployeeValues }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { isSuperUser, values } = action.payload;
			const { callback } = action;

			const currentEndpoint = isSuperUser
				? API.employeeService.editOwnProfileSuperUser
				: API.employeeService.editOwnProfileNonSuperUser;
			const updatedProfile = yield call(currentEndpoint, values);

			yield put({ type: TYPES.EDIT_OWN_PROFILE.SUCCESS, payload: updatedProfile });

			callback && (yield call(callback, updatedProfile));

			yield call(message.success, MESSAGES.yourProfileUpdated);
		} catch (error) {
			yield put({ type: TYPES.EDIT_OWN_PROFILE.FAILED, payload: error });
		}
	}

	function* getEmployeesListSaga(
		action: GenericAction<IEmployeeValues>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload: params, isSuperUser } = action;

			const url = isSuperUser
				? API.employeeService.getEmployeesListSuperUser
				: API.employeeService.getEmployeesListNonSuperUser;

			const list = yield call(
				url,
				params || {
					size: 20,
					active: true,
				},
			);

			if (!list?.data?.length && params?.search) {
				yield call(message.info, MESSAGES.noFoundBySearch);
			}

			yield put({ type: TYPES.VIEW_EMPLOYEES_LIST.SUCCESS, payload: list });
		} catch (error) {
			yield put({ type: TYPES.VIEW_EMPLOYEES_LIST.FAILED, payload: error });
		}
	}

	function* archiveEmployeeSaga(
		action: GenericAction<{ employeeId: number; leaveDate: string }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.employeeService.archiveEmployee, payload);

			yield put({ type: TYPES.ARCHIVE_EMPLOYEE.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyArchivedEmployee);
		} catch (error) {
			yield put({ type: TYPES.ARCHIVE_EMPLOYEE.FAILED, payload: error });
		}
	}

	function* unArchiveEmployeeSaga(
		action: GenericAction<{ employeeId: number }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.employeeService.unArchiveEmployee, payload);

			yield put({ type: TYPES.UNARCHIVE_EMPLOYEE.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyUnArchivedEmployee);
		} catch (error) {
			yield put({ type: TYPES.UNARCHIVE_EMPLOYEE.FAILED, payload: error });
		}
	}

	function* changeEmployeeStatusSaga(
		action: GenericAction<{
			employeeId: number;
			employeeStatus: number;
			startDate?: string;
			leaveDate?: string;
		}>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.employeeService.changeEmployeeStatus, payload);

			yield put({ type: TYPES.CHANGE_EMPLOYEE_STATUS.SUCCESS });

			if (callback) {
				yield call(callback);
			}

			yield call(message.success, MESSAGES.successfullyUpdatedEmployeeStatus);
		} catch (error) {
			yield put({ type: TYPES.CHANGE_EMPLOYEE_STATUS.FAILED, payload: error });
		}
	}

	function* getEmployeeByIdSaga(
		action: GenericAction<IEmployeeValues>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload: employeeId, isSuperUser } = action;

			const url = isSuperUser
				? API.employeeService.getEmployeeByIdSuperUser
				: API.employeeService.getEmployeeByIdNonSuperUser;

			const employeesData = yield call(url, employeeId);

			yield put({ type: TYPES.GET_EMPLOYEE_BY_ID.SUCCESS, payload: employeesData });
		} catch (error) {
			yield put({ type: TYPES.GET_EMPLOYEE_BY_ID.FAILED, payload: error });
		}
	}

	function* sendFirstDayInvitationSaga(
		action: GenericAction<IEmployeeValues>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			yield call(API.employeeService.sendFirstDayEmployeeInvitation, action.payload);

			yield put({ type: TYPES.SEND_FIRST_DAY_INVITATION.SUCCESS });

			yield call(message.success, MESSAGES.successfullySentFirstDayInvitation);
		} catch (error) {
			yield put({ type: TYPES.SEND_FIRST_DAY_INVITATION.FAILED, payload: error });
		}
	}

	function* getEmployeeShortListSaga(
		action: GenericAction<IEmployeeValues>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const employeeList = yield call(API.employeeService.getEmployeeShortList);

			yield put({ type: TYPES.GET_EMPLOYEE_SHORT_LIST.SUCCESS, payload: employeeList });
		} catch (error) {
			yield put({ type: TYPES.GET_EMPLOYEE_SHORT_LIST.FAILED, payload: error });
		}
	}

	function* getEmployeeAttachmentsSaga(
		action: GenericAction<{ employeeId: number; isSuperUser: boolean }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload } = action;

			const url = payload?.isSuperUser
				? API.employeeService.getEmployeeAttachmentsById
				: API.employeeService.getOwnEmployeeAttachments;

			const attachments = yield call(url, payload?.employeeId);

			yield put({ type: TYPES.GET_EMPLOYEE_ATTACHMENTS.SUCCESS, payload: attachments });
		} catch (error) {
			yield put({ type: TYPES.GET_EMPLOYEE_ATTACHMENTS.FAILED, payload: error });
		}
	}

	function* saveEmployeeAttachmentsSaga(
		action: GenericAction<{
			attachments: string[];
			employeeId: number;
			isSuperUser: boolean;
		}>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			const url = payload?.isSuperUser
				? API.employeeService.updateOtherEmployeeAttachments
				: API.employeeService.updateOwnEmployeeAttachments;

			yield call(url, payload);

			yield put({ type: TYPES.SAVE_EMPLOYEE_ATTACHMENTS.SUCCESS });

			if (callback) {
				yield call(callback);
			}

			yield call(message.success, MESSAGES.successfullyUpdatedAttachments);
		} catch (error) {
			yield put({ type: TYPES.SAVE_EMPLOYEE_ATTACHMENTS.FAILED, payload: error });
		}
	}

	function* getHRVacationSettingsSaga(): Generator<CallEffect | PutEffect, void> {
		try {
			const vacationSettings = yield call(API.employeeService.getHRVacationSettings);

			yield put({ type: TYPES.GET_HR_VACATION_SETTINGS.SUCCESS, payload: vacationSettings });
		} catch (error) {
			yield put({ type: TYPES.GET_HR_VACATION_SETTINGS.FAILED, payload: error });
		}
	}

	function* createUpdateVacationSettingsSaga(
		action: GenericAction<{ data: { employee: number; days: number } }>,
	): Generator<CallEffect | PutEffect, void> {
		const { callback } = action;
		try {
			const { payload } = action;

			const vacationSetting = yield call(
				API.employeeService.createUpdateHRVacationSettings,
				payload,
			);

			yield put({ type: TYPES.CREATE_UPDATE_VACATION_SETTINGS.SUCCESS, payload: vacationSetting });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyAddedTimeOff);
		} catch (error) {
			yield put({ type: TYPES.CREATE_UPDATE_VACATION_SETTINGS.FAILED, payload: error });

			callback && (yield call(callback, error));

			yield call(message.warning, MESSAGES.failedToUpdateDeleteCustomVacation);
		}
	}

	function* deleteVacationSettingsSaga(
		action: GenericAction<{ employeeId: string }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.employeeService.deleteHRVacationSettings, payload);

			yield put({ type: TYPES.DELETE_VACATION_SETTINGS.SUCCESS, payload });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyDeletedTimeOff);
		} catch (error) {
			yield put({ type: TYPES.DELETE_VACATION_SETTINGS.FAILED, payload: error });

			yield call(message.warning, MESSAGES.failedToUpdateDeleteCustomVacation);
		}
	}

	function* getEmployeeOptionsSaga(
		action: GenericAction<IEmployeeValues>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const employeeOptions = yield call(API.employeeService.getEmployeeOptions);

			yield put({ type: TYPES.GET_EMPLOYEE_OPTIONS.SUCCESS, payload: employeeOptions });
		} catch (error) {
			yield put({ type: TYPES.GET_EMPLOYEE_OPTIONS.FAILED, payload: error });
		}
	}

	function* getWorkWeekSettingsSaga(
		action: GenericAction<IEmployeeValues>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const workWeekSettings = yield call(API.settingsService.getHRWorkWeekSettings);

			yield put({ type: TYPES.GET_WORK_WEEK_SETTINGS.SUCCESS, payload: workWeekSettings });
		} catch (error) {
			yield put({ type: TYPES.GET_WORK_WEEK_SETTINGS.FAILED, payload: error });
		}
	}

	function* updateWorkWeekSettingsSaga(
		action: GenericAction<{ employeeId: string }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload } = action;

			yield call(API.settingsService.updateHRWorkWeekSettings, payload);

			yield put({ type: TYPES.UPDATE_WORK_WEEK_SETTINGS.SUCCESS, payload });

			yield call(message.success, MESSAGES.successfullyUpdatedWorkWeekSettings);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_WORK_WEEK_SETTINGS.FAILED, payload: error });
		}
	}

	function* getTimeOffRequestDurationSaga(
		action: GenericAction<GetTimeOffRequestDurationType>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload } = action;

			const duration = yield call(API.timeOffRequestsService.getTimeOffRequestDuration, payload);

			yield put({ type: TYPES.GET_TIME_OFF_REQUEST_DURATION.SUCCESS, payload: duration });
		} catch (error) {
			yield put({ type: TYPES.GET_TIME_OFF_REQUEST_DURATION.FAILED, payload: error });
		}
	}

	function* createTimeOffRequestSaga(
		action: GenericAction<CreateTimeOffRequestType>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.timeOffRequestsService.createTimeOffRequest, payload);

			yield put({ type: TYPES.CREATE_TIME_OFF_REQUEST.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyCreatedTimeOffRequest);
		} catch (error) {
			yield put({ type: TYPES.CREATE_TIME_OFF_REQUEST.FAILED, payload: error });
		}
	}

	function* updateTimeOffRequestSaga(
		action: GenericAction<UpdateTimeOffRequestType>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.timeOffRequestsService.updateTimeOffRequest, payload);

			yield put({ type: TYPES.UPDATE_TIME_OFF_REQUEST.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyUpdatedTimeOffRequest);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_TIME_OFF_REQUEST.FAILED, payload: error });
		}
	}

	function* getTimeOffStaticsPerEmployeeSaga(
		action: GenericAction<GetTimeOffRequestDurationType>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload } = action;

			const requestTimeOffStatistics = yield call(
				API.timeOffRequestsService.getTimeOffStaticsPerEmployee,
				payload,
			);

			yield put({
				type: TYPES.GET_TIME_OFF_STATICS_PER_EMPLOYEE.SUCCESS,
				payload: requestTimeOffStatistics,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_TIME_OFF_STATICS_PER_EMPLOYEE.FAILED, payload: error });
		}
	}

	function* getTimeOffStaticsPerEmployeeYearsSaga(
		action: GenericAction<TimeOffStaticsPerEmployeeRequestedType>,
	): Generator<CallEffect | PutEffect | AllEffect<CallEffect<unknown>>, void> {
		try {
			const { payload } = action;
			const { years, ...restPayload } = payload;

			const requests = years?.map((year) =>
				call(API.timeOffRequestsService.getTimeOffStaticsPerEmployee, {
					...restPayload,
					year,
				}),
			);

			const results = yield all(requests);

			const combinedStatistics = combineTimeOffStatistics(results);

			yield put({
				type: TYPES.GET_TIME_OFF_STATICS_PER_EMPLOYEE_YEARS.SUCCESS,
				payload: combinedStatistics,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_TIME_OFF_STATICS_PER_EMPLOYEE_YEARS.FAILED, payload: error });
		}
	}

	function* getAllTimeOffRequestsSaga(
		action: GenericAction<GetTimeOffRequestDurationType>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload } = action;

			const requestTimeOffData = yield call(
				API.timeOffRequestsService.getAllTimeOffRequests,
				payload,
			);

			yield put({
				type: TYPES.GET_ALL_TIME_OFF_REQUESTS.SUCCESS,
				payload: requestTimeOffData,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_ALL_TIME_OFF_REQUESTS.FAILED, payload: error });
		}
	}

	function* getBankHolidaysSaga(
		action: GenericAction<GetTimeOffRequestDurationType>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload } = action;

			const bankHolidays = yield call(API.timeOffRequestsService.getBankHolidays, payload);

			yield put({
				type: TYPES.GET_BANK_HOLIDAYS.SUCCESS,
				payload: bankHolidays,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_BANK_HOLIDAYS.FAILED, payload: error });
		}
	}

	function* approveTimeOffRequestSaga(
		action: GenericAction<{ id: number }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.timeOffRequestsService.approveTimeOffRequest, payload);

			yield put({
				type: TYPES.APPROVE_TIME_OFF_REQUEST.SUCCESS,
			});

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyApprovedTimeOffRequest);
		} catch (error) {
			yield put({ type: TYPES.APPROVE_TIME_OFF_REQUEST.FAILED, payload: error });
		}
	}

	function* approveAllTimeOffRequestsSaga(
		action: GenericAction<void>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { callback } = action;

			yield call(API.timeOffRequestsService.approveAllTimeOffRequests);

			yield put({
				type: TYPES.APPROVE_ALL_TIME_OFF_REQUESTS.SUCCESS,
			});

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyApprovedAllTimeOffRequest);
		} catch (error) {
			yield put({ type: TYPES.APPROVE_ALL_TIME_OFF_REQUESTS.FAILED, payload: error });
		}
	}

	function* declineTimeOffRequestSaga(
		action: GenericAction<{ requestId: number; comment: string | null }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.timeOffRequestsService.declineTimeOffRequest, payload);

			yield put({
				type: TYPES.DECLINE_TIME_OFF_REQUEST.SUCCESS,
			});

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyDeletedTimeOffRequest);
		} catch (error) {
			yield put({ type: TYPES.DECLINE_TIME_OFF_REQUEST.FAILED, payload: error });
		}
	}

	function* deleteTimeOffRequestSaga(
		action: GenericAction<{ requestId: number }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.timeOffRequestsService.deleteTimeOffRequest, payload);

			yield put({
				type: TYPES.DELETE_TIME_OFF_REQUEST.SUCCESS,
			});

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyDeletedTimeOffRequest);
		} catch (error) {
			yield put({ type: TYPES.DELETE_TIME_OFF_REQUEST.FAILED, payload: error });
		}
	}

	function* createCustomHolidaySaga(
		action: GenericAction<BankHolidayBaseType>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.timeOffRequestsService.createCustomHoliday, payload);

			yield put({ type: TYPES.CREATE_CUSTOM_HOLIDAY.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyCreatedCustomHoliday);
		} catch (error) {
			yield put({ type: TYPES.CREATE_CUSTOM_HOLIDAY.FAILED, payload: error });
		}
	}

	function* updateCustomHolidaySaga(
		action: GenericAction<BankHolidayType>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.timeOffRequestsService.updateCustomHoliday, payload);

			yield put({ type: TYPES.UPDATE_CUSTOM_HOLIDAY.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyUpdatedCustomHoliday);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_CUSTOM_HOLIDAY.FAILED, payload: error });
		}
	}

	function* deleteCustomHolidaySaga(
		action: GenericAction<{ id: number }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.timeOffRequestsService.deleteCustomHoliday, payload);

			yield put({ type: TYPES.DELETE_CUSTOM_HOLIDAY.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyDeletedCustomHoliday);
		} catch (error) {
			yield put({ type: TYPES.DELETE_CUSTOM_HOLIDAY.FAILED, payload: error });
		}
	}

	function* getTimeOffRequestsPendingSaga(
		action: GenericAction<void>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const timeOffRequestsPending = yield call(
				API.timeOffRequestsService.getTimeOffRequestsPending,
			);

			yield put({
				type: TYPES.GET_TIME_OFF_REQUESTS_PENDING.SUCCESS,
				payload: timeOffRequestsPending,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_TIME_OFF_REQUESTS_PENDING.FAILED, payload: error });
		}
	}

	function* getTimeOffRequestsForEmployeeByIdSaga(
		action: GenericAction<{ employeeId: number }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload } = action;

			const employeeData = yield call(
				API.timeOffRequestsService.getTimeOffRequestsForEmployeeById,
				payload,
			);

			yield put({
				type: TYPES.GET_TIME_OFF_REQUESTS_FOR_EMPLOYEE_BY_ID.SUCCESS,
				payload: employeeData,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_TIME_OFF_REQUESTS_FOR_EMPLOYEE_BY_ID.FAILED, payload: error });
		}
	}

	function* getTimeOffStaticsCompanySaga(
		action: GenericAction<{ onlyApproved: boolean }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload } = action;

			const requestTimeOffStatistics = yield call(
				API.timeOffRequestsService.getTimeOffStaticsCompanyWide,
				payload,
			);

			yield put({
				type: TYPES.GET_TIME_OFF_STATICS_COMPANY.SUCCESS,
				payload: requestTimeOffStatistics,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_TIME_OFF_STATICS_COMPANY.FAILED, payload: error });
		}
	}

	return {
		getEmployeeStatusesSaga,
		createEmployeeSaga,
		getEmployeesListSaga,
		archiveEmployeeSaga,
		unArchiveEmployeeSaga,
		changeEmployeeStatusSaga,
		getEmployeeByIdSaga,
		sendFirstDayInvitationSaga,
		getEmployeeAttachmentsSaga,
		saveEmployeeAttachmentsSaga,
		editEmployeeSaga,
		editOwnProfileSaga,
		getOwnProfileSaga,
		getEmployeeShortListSaga,
		getOnOffBoardingSettingsSaga,
		setOnOffBoardingSettingsSaga,
		getHRVacationSettingsSaga,
		createUpdateVacationSettingsSaga,
		deleteVacationSettingsSaga,
		getEmployeeOptionsSaga,
		getWorkWeekSettingsSaga,
		updateWorkWeekSettingsSaga,
		getTimeOffRequestDurationSaga,
		createTimeOffRequestSaga,
		updateTimeOffRequestSaga,
		getTimeOffStaticsPerEmployeeSaga,
		getTimeOffStaticsPerEmployeeYearsSaga,
		getAllTimeOffRequestsSaga,
		getBankHolidaysSaga,
		approveTimeOffRequestSaga,
		approveAllTimeOffRequestsSaga,
		declineTimeOffRequestSaga,
		deleteTimeOffRequestSaga,
		createCustomHolidaySaga,
		updateCustomHolidaySaga,
		deleteCustomHolidaySaga,
		getTimeOffRequestsPendingSaga,
		getTimeOffRequestsForEmployeeByIdSaga,
		getTimeOffStaticsCompanySaga,
	};
};
